<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="tree-progress-bar"></mat-progress-bar>
<div class="lc-container">
    <h1 *ngIf="landedCostItemsRequest as request">
        Landed Cost calculation for
        <mat-chip>{{ request.hsCode }}</mat-chip>
        from
        <mat-chip>{{ request.fromCountry }}</mat-chip>
        to
        <mat-chip>{{ request.toCountry }}</mat-chip>
    </h1>
    <form class="lc-form">
        <div *ngFor="let landCostGroup of landedCostItems">

            <div class="flex-form-fields">
                <mat-form-field *ngFor="let landedCostItem of landCostGroup">
                    <mat-label>{{ landedCostItem.label }}</mat-label>

                    <input matInput [(ngModel)]="landedCostItem.value" [type]="landedCostItem.type"
                           [id]="landedCostItem.key" [name]="landedCostItem.key">
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <button mat-raised-button color="primary" (click)="calculate(landedCostItems)">Calculate</button>
        </div>
    </form>
    <mat-divider></mat-divider>
    <div *ngIf="result">
        <h2>Result</h2>
        <mat-list>
            <mat-list-item>{{result.name}} - {{result.result}}</mat-list-item>
            <mat-list-item>
                <div matListItemTitle>Formula: {{result.formula}}</div>
                <div matListItemLine>{{result.substitutedFormula}}</div>
            </mat-list-item>
            <mat-list-item *ngFor="let item of result?.nestedResults" matTooltip="{{item?.description + ': ' + item?.formula}}">
                <span matListItemTitle>{{ item?.name }}: {{ item?.result }} <mat-chip *ngIf="item?.isDeminimis === true">DeMinimis</mat-chip></span>
                <span matListItemLine>{{ item?.substitutedFormula }}</span>
            </mat-list-item>
        </mat-list>
    </div>
</div>
