import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HsTreeComponent} from "./hs-tree/hs-tree.component";
import {ApiDocsComponent} from "./api-docs/api-docs.component";
import {GeoComponent} from "./geo/geo.component";
import {ClassifyComponent} from "./classification/classify.component";
import {LandedCostComponent} from "./landed-cost/landed-cost.component";

const routes: Routes = [
    {path: 'hs-tree', component: HsTreeComponent},
    {path: 'api-docs', component: ApiDocsComponent, data: {type: 'API'}},
    {path: 'auth-docs', component: ApiDocsComponent, data: {type: 'Auth'}},
    {path: 'geo', component: GeoComponent},
    {path: 'classify', component: ClassifyComponent},
    {path: 'landed-cost', component: LandedCostComponent},
    {path: '', redirectTo: '/hs-tree', pathMatch: 'full'}//, // redirect to `first-component`
    // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
