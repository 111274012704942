<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="tree-progress-bar"></mat-progress-bar>
<div class="container">
    <form class="form" [formGroup]="classifyForm">
        <country-select [countryType]="1"></country-select>
        <mat-form-field appearance="fill">
            <mat-label>Locale</mat-label>
            <mat-select formControlName="locale">
                <mat-option *ngFor="let locale of locales" [value]="locale">
                    {{ locale }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="fuzzy">Fuzzy</mat-checkbox>
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Fix Category</mat-label>
            <input type="text" formControlName="category" matInput>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Fix Sub Category</mat-label>
            <input type="text" formControlName="subCategory" matInput>
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>Type product description</mat-label>
            <input type="text" formControlName="product" matInput [matAutocomplete]="auto">

            <mat-autocomplete #auto="matAutocomplete">
                <mat-optgroup *ngFor="let category of categories | async">
                    <span><b>{{category.category}}</b></span>
                    <mat-option *ngFor="let item of category.items" [value]="item.hsCode" (onSelectionChange)="selectItem(item)">
                        <small class="hs-code-color">{{item.hsCode}}</small> | <small>{{item.productEn}}</small> | <small>{{item.product}}</small>
                    </mat-option>
                </mat-optgroup>
            </mat-autocomplete>
        </mat-form-field>

        <mat-slider min="0" max="100" step="10" showTickMarks discrete>
            <input matSliderThumb formControlName="size">
        </mat-slider>
    </form>
    <mat-divider></mat-divider>
    <code-highlight>{{ displayResult }}</code-highlight>
</div>
