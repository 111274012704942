import {Component, Input, OnChanges} from "@angular/core";
import {icon, latLng, marker, tileLayer} from "leaflet";
import {GeoLocation} from "../../service/API.service";

@Component({
    selector: "geomap",
    template: `
        <div *ngIf="options" id="map" class="map" leaflet [leafletOptions]="options" [leafletLayers]="layers"
             [(leafletCenter)]="center"></div>
    `,
    styleUrls: ["./map.component.sass"]
})
export class MapComponent implements OnChanges {
    @Input() location: GeoLocation | null | undefined;

    public options: any;
    public layers: any;
    public center: any;

    public layersControlOptions = {position: 'bottomright'}

    public ngOnChanges(): void {
        if (this.location) {
            const lat = Number.parseFloat(this.location?.latitude ?? "0");
            const lng = Number.parseFloat(this.location?.longitude ?? "0");

            this.options = {
                layers: [
                    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
                ],
                zoom: 18,
                center: latLng(lat, lng),
                zoomControl: false,
                scrollWheelZoom: "center"
            };

            this.layers = [
                marker([lat, lng], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                })
            ];

            this.center = latLng(lat, lng);
        }
    }
}
