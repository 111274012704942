import { Component, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import hljs from "highlight.js";
import json from 'highlight.js/lib/languages/json';

@Component({
    selector: 'code-highlight',
    template: '<pre><code><ng-content></ng-content></code></pre>',
    styleUrls: ['./code.component.sass'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CodeComponent implements OnChanges {
    @Input() code: string = '';

    constructor(private elRef: ElementRef) {
        hljs.registerLanguage('json', json);
    }

    ngOnChanges() {
        hljs.highlightElement(this.elRef.nativeElement.querySelector('code'));
    }
}
