/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";

export type Job = {
  __typename: "Job";
  country: string;
  jobStatus: JobStatus;
  startDate: string;
  eventId?: string | null;
  taskId?: string | null;
  endDate?: string | null;
  documentUrl?: string | null;
  errors?: string | null;
};

export enum JobStatus {
  STARTED = "STARTED",
  SUCCEED = "SUCCEED",
  FAILED = "FAILED",
  UNKNOWN = "UNKNOWN"
}

export type TradeData = {
  dataVersion: string;
  hsCode: string;
  level: number;
  parent_version_hsCode_level_id?: string | null;
  indent: number;
  hasChildItems: boolean;
  declarable: boolean;
  hidden: boolean;
  description: Array<DescriptionInput>;
  unit: string;
  rates: Array<RateInput | null>;
};

export type DescriptionInput = {
  itemLevelDescription: string;
  language: string;
};

export type RateInput = {
  costCalculationItemName: string;
  formula: FormulaInput;
};

export type FormulaInput = {
  value: string;
  minValue?: string | null;
  maxValue?: string | null;
};

export type CodeNode = {
  __typename: "CodeNode";
  countryCode?: string | null;
  createdOn?: number | null;
  dataVersion?: string | null;
  declarable?: boolean | null;
  descEffective?: string | null;
  descExpiry?: string | null;
  description?: Array<Description | null> | null;
  hasChildItems?: boolean | null;
  hidden?: boolean | null;
  hsCode?: string | null;
  hsEffective?: string | null;
  hsExpiry?: string | null;
  indent?: number | null;
  level?: number | null;
  parent_version_hsCode_level_id?: string | null;
  unit?: string | null;
  updatedOn?: number | null;
  version_hsCode_level_id: string;
  rates?: Array<Rate | null> | null;
};

export type Description = {
  __typename: "Description";
  fullDescription?: string | null;
  itemLevelDescription: string;
  language: string;
};

export type Rate = {
  __typename: "Rate";
  costCalculationItemName: string;
  formula: Formula;
};

export type Formula = {
  __typename: "Formula";
  value: string;
  minValue?: string | null;
  maxValue?: string | null;
};

export type CommercialRestrictionInput = {
  productId: string;
  productName: Array<LanguageItemInput>;
  hsCodes: Array<RestrictedHsCodeInput>;
  partners: Array<PartnerRestrictionInput>;
};

export type LanguageItemInput = {
  locale: string;
  text: string;
};

export type RestrictedHsCodeInput = {
  country: string;
  hsCode: string;
};

export type PartnerRestrictionInput = {
  partnerName: string;
  importRestrictions: RestrictionsByOwnershipInput;
  exportRestrictions: RestrictionsByOwnershipInput;
};

export type RestrictionsByOwnershipInput = {
  individual: Array<CommercialRestrictionItemInput>;
  business: Array<CommercialRestrictionItemInput>;
};

export type CommercialRestrictionItemInput = {
  country: string;
  restriction: number;
};

export type CommercialRestriction = {
  __typename: "CommercialRestriction";
  productId: string;
  productName: Array<LanguageItem>;
  hsCodes: Array<RestrictedHsCode>;
  partners: Array<PartnerRestriction>;
};

export type LanguageItem = {
  __typename: "LanguageItem";
  locale: string;
  text: string;
};

export type RestrictedHsCode = {
  __typename: "RestrictedHsCode";
  country: string;
  hsCode: string;
};

export type PartnerRestriction = {
  __typename: "PartnerRestriction";
  partnerName: string;
  importRestrictions: RestrictionsByOwnership;
  exportRestrictions: RestrictionsByOwnership;
};

export type RestrictionsByOwnership = {
  __typename: "RestrictionsByOwnership";
  individual: Array<CommercialRestrictionItem>;
  business: Array<CommercialRestrictionItem>;
};

export type CommercialRestrictionItem = {
  __typename: "CommercialRestrictionItem";
  country: string;
  restriction: number;
};

export type CustomerApiTokens = {
  __typename: "CustomerApiTokens";
  xApiKey: string;
  clientCredentials: string;
};

export type CountryData = {
  __typename: "CountryData";
  countryCode: string;
  countryGroups?: Array<CountryGroup | null> | null;
  currencyCode?: string | null;
  costCalculationItems?: Array<CostCalculationItem | null> | null;
  dataVersions?: Array<CountryDataVersion | null> | null;
  name?: string | null;
  isGroup?: boolean | null;
  tariff?: boolean | null;
  classification?: boolean | null;
  geoData?: boolean | null;
  zzRate?: string | null;
  defaultValuationBase?: string | null;
  declarableLength?: number | null;
};

export type CountryGroup = {
  __typename: "CountryGroup";
  name?: string | null;
  countryCodes?: Array<string | null> | null;
  code?: string | null;
  abbreviation?: string | null;
};

export type CostCalculationItem = {
  __typename: "CostCalculationItem";
  name?: string | null;
  description?: string | null;
  type?: string | null;
  level?: string | null;
  domain?: string | null;
  formula?: string | null;
  valuationBase?: string | null;
  deMinimis?: Array<DeMinimis | null> | null;
  countriesOfOrigin?: CountriesAndGroups | null;
  shipFromCountries?: CountriesAndGroups | null;
};

export type DeMinimis = {
  __typename: "DeMinimis";
  shipmentMethod?: Array<string | null> | null;
  importer?: string | null;
  nonZeroRate?: string | null;
  exemptBelowValBase?: number | null;
  exemptBelowCharge?: number | null;
  exemptBelowLandedCost?: number | null;
  altCurrency?: string | null;
  altValuationBase?: string | null;
  notExemptedCodes?: Array<string | null> | null;
  shipFromCountries?: CountriesAndGroups | null;
  countriesOfOrigin?: CountriesAndGroups | null;
  specialType?: string | null;
};

export type CountriesAndGroups = {
  __typename: "CountriesAndGroups";
  countryCodes?: Array<string | null> | null;
  groupCodes?: Array<string | null> | null;
};

export type CountryDataVersion = {
  __typename: "CountryDataVersion";
  isLatest?: boolean | null;
  externalVersion?: string | null;
  tariffVersion?: string | null;
  value?: string | null;
};

export type CountryLoadInfo = {
  __typename: "CountryLoadInfo";
  CountryCode: string;
  CountryName?: string | null;
  FilePath?: string | null;
  UnzippedFileName?: string | null;
  LoadedFileName?: string | null;
  SFTPReceiveDate?: string | null;
  DataLoadDate?: string | null;
  MoveSpreadsheetDate?: string | null;
  SpreadsheetID?: string | null;
  MoveFinished?: boolean | null;
};

export type GeoLocation = {
  __typename: "GeoLocation";
  countryCode?: string | null;
  postalCode?: string | null;
  placeName?: string | null;
  placeNameASCII?: string | null;
  placeNameUk?: string | null;
  placeNameRu?: string | null;
  placeNameAKA?: string | null;
  adminName1?: string | null;
  adminName1Uk?: string | null;
  adminName1Ru?: string | null;
  adminCode1?: string | null;
  adminName2?: string | null;
  adminCode2?: string | null;
  adminName3?: string | null;
  adminCode3?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  accuracy?: string | null;
};

export type ClassifyInput = {
  keyword: string;
  category?: string | null;
  subCategory?: string | null;
  locale: string;
  fuzzy: boolean;
  size: number;
};

export type Classification = {
  __typename: "Classification";
  countryCode: string;
  locale: string;
  fuzziness: boolean;
  items: Array<ClassifyItem>;
};

export type ClassifyItem = {
  __typename: "ClassifyItem";
  id?: string | null;
  hsCode?: string | null;
  rawHsCode?: string | null;
  productEn?: string | null;
  product?: string | null;
  categoryEn?: string | null;
  category?: string | null;
  subCategoryEn?: string | null;
  subCategory?: string | null;
  keywordsEn?: string | null;
  keywords?: string | null;
  descriptionEn?: string | null;
  description?: string | null;
  noExportFromUA?: boolean | null;
  noImportToUA?: boolean | null;
};

export type RunJobMutation = {
  __typename: "Job";
  country: string;
  jobStatus: JobStatus;
  startDate: string;
  eventId?: string | null;
  taskId?: string | null;
  endDate?: string | null;
  documentUrl?: string | null;
  errors?: string | null;
};

export type CreateTradeDataMutation = {
  __typename: "CodeNode";
  countryCode?: string | null;
  createdOn?: number | null;
  dataVersion?: string | null;
  declarable?: boolean | null;
  descEffective?: string | null;
  descExpiry?: string | null;
  description?: Array<{
    __typename: "Description";
    fullDescription?: string | null;
    itemLevelDescription: string;
    language: string;
  } | null> | null;
  hasChildItems?: boolean | null;
  hidden?: boolean | null;
  hsCode?: string | null;
  hsEffective?: string | null;
  hsExpiry?: string | null;
  indent?: number | null;
  level?: number | null;
  parent_version_hsCode_level_id?: string | null;
  unit?: string | null;
  updatedOn?: number | null;
  version_hsCode_level_id: string;
  rates?: Array<{
    __typename: "Rate";
    costCalculationItemName: string;
    formula: {
      __typename: "Formula";
      value: string;
      minValue?: string | null;
      maxValue?: string | null;
    };
  } | null> | null;
};

export type CreateNpRestrictionMutation = {
  __typename: "CommercialRestriction";
  productId: string;
  productName: Array<{
    __typename: "LanguageItem";
    locale: string;
    text: string;
  }>;
  hsCodes: Array<{
    __typename: "RestrictedHsCode";
    country: string;
    hsCode: string;
  }>;
  partners: Array<{
    __typename: "PartnerRestriction";
    partnerName: string;
    importRestrictions: {
      __typename: "RestrictionsByOwnership";
      individual: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
      business: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
    };
    exportRestrictions: {
      __typename: "RestrictionsByOwnership";
      individual: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
      business: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
    };
  }>;
};

export type GetApiTokensQuery = {
  __typename: "CustomerApiTokens";
  xApiKey: string;
  clientCredentials: string;
};

export type GetNodeQuery = {
  __typename: "CodeNode";
  countryCode?: string | null;
  createdOn?: number | null;
  dataVersion?: string | null;
  declarable?: boolean | null;
  descEffective?: string | null;
  descExpiry?: string | null;
  description?: Array<{
    __typename: "Description";
    fullDescription?: string | null;
    itemLevelDescription: string;
    language: string;
  } | null> | null;
  hasChildItems?: boolean | null;
  hidden?: boolean | null;
  hsCode?: string | null;
  hsEffective?: string | null;
  hsExpiry?: string | null;
  indent?: number | null;
  level?: number | null;
  parent_version_hsCode_level_id?: string | null;
  unit?: string | null;
  updatedOn?: number | null;
  version_hsCode_level_id: string;
  rates?: Array<{
    __typename: "Rate";
    costCalculationItemName: string;
    formula: {
      __typename: "Formula";
      value: string;
      minValue?: string | null;
      maxValue?: string | null;
    };
  } | null> | null;
};

export type ListNodesByLevelQuery = {
  __typename: "CodeNode";
  countryCode?: string | null;
  createdOn?: number | null;
  dataVersion?: string | null;
  declarable?: boolean | null;
  descEffective?: string | null;
  descExpiry?: string | null;
  description?: Array<{
    __typename: "Description";
    fullDescription?: string | null;
    itemLevelDescription: string;
    language: string;
  } | null> | null;
  hasChildItems?: boolean | null;
  hidden?: boolean | null;
  hsCode?: string | null;
  hsEffective?: string | null;
  hsExpiry?: string | null;
  indent?: number | null;
  level?: number | null;
  parent_version_hsCode_level_id?: string | null;
  unit?: string | null;
  updatedOn?: number | null;
  version_hsCode_level_id: string;
  rates?: Array<{
    __typename: "Rate";
    costCalculationItemName: string;
    formula: {
      __typename: "Formula";
      value: string;
      minValue?: string | null;
      maxValue?: string | null;
    };
  } | null> | null;
};

export type ListNodesQuery = {
  __typename: "CodeNode";
  countryCode?: string | null;
  createdOn?: number | null;
  dataVersion?: string | null;
  declarable?: boolean | null;
  descEffective?: string | null;
  descExpiry?: string | null;
  description?: Array<{
    __typename: "Description";
    fullDescription?: string | null;
    itemLevelDescription: string;
    language: string;
  } | null> | null;
  hasChildItems?: boolean | null;
  hidden?: boolean | null;
  hsCode?: string | null;
  hsEffective?: string | null;
  hsExpiry?: string | null;
  indent?: number | null;
  level?: number | null;
  parent_version_hsCode_level_id?: string | null;
  unit?: string | null;
  updatedOn?: number | null;
  version_hsCode_level_id: string;
  rates?: Array<{
    __typename: "Rate";
    costCalculationItemName: string;
    formula: {
      __typename: "Formula";
      value: string;
      minValue?: string | null;
      maxValue?: string | null;
    };
  } | null> | null;
};

export type GetCountryQuery = {
  __typename: "CountryData";
  countryCode: string;
  countryGroups?: Array<{
    __typename: "CountryGroup";
    name?: string | null;
    countryCodes?: Array<string | null> | null;
    code?: string | null;
    abbreviation?: string | null;
  } | null> | null;
  currencyCode?: string | null;
  costCalculationItems?: Array<{
    __typename: "CostCalculationItem";
    name?: string | null;
    description?: string | null;
    type?: string | null;
    level?: string | null;
    domain?: string | null;
    formula?: string | null;
    valuationBase?: string | null;
    deMinimis?: Array<{
      __typename: "DeMinimis";
      shipmentMethod?: Array<string | null> | null;
      importer?: string | null;
      nonZeroRate?: string | null;
      exemptBelowValBase?: number | null;
      exemptBelowCharge?: number | null;
      exemptBelowLandedCost?: number | null;
      altCurrency?: string | null;
      altValuationBase?: string | null;
      notExemptedCodes?: Array<string | null> | null;
      shipFromCountries?: {
        __typename: "CountriesAndGroups";
        countryCodes?: Array<string | null> | null;
        groupCodes?: Array<string | null> | null;
      } | null;
      countriesOfOrigin?: {
        __typename: "CountriesAndGroups";
        countryCodes?: Array<string | null> | null;
        groupCodes?: Array<string | null> | null;
      } | null;
      specialType?: string | null;
    } | null> | null;
    countriesOfOrigin?: {
      __typename: "CountriesAndGroups";
      countryCodes?: Array<string | null> | null;
      groupCodes?: Array<string | null> | null;
    } | null;
    shipFromCountries?: {
      __typename: "CountriesAndGroups";
      countryCodes?: Array<string | null> | null;
      groupCodes?: Array<string | null> | null;
    } | null;
  } | null> | null;
  dataVersions?: Array<{
    __typename: "CountryDataVersion";
    isLatest?: boolean | null;
    externalVersion?: string | null;
    tariffVersion?: string | null;
    value?: string | null;
  } | null> | null;
  name?: string | null;
  isGroup?: boolean | null;
  tariff?: boolean | null;
  classification?: boolean | null;
  geoData?: boolean | null;
  zzRate?: string | null;
  defaultValuationBase?: string | null;
  declarableLength?: number | null;
};

export type ListCountriesQuery = {
  __typename: "CountryData";
  countryCode: string;
  countryGroups?: Array<{
    __typename: "CountryGroup";
    name?: string | null;
    countryCodes?: Array<string | null> | null;
    code?: string | null;
    abbreviation?: string | null;
  } | null> | null;
  currencyCode?: string | null;
  costCalculationItems?: Array<{
    __typename: "CostCalculationItem";
    name?: string | null;
    description?: string | null;
    type?: string | null;
    level?: string | null;
    domain?: string | null;
    formula?: string | null;
    valuationBase?: string | null;
    deMinimis?: Array<{
      __typename: "DeMinimis";
      shipmentMethod?: Array<string | null> | null;
      importer?: string | null;
      nonZeroRate?: string | null;
      exemptBelowValBase?: number | null;
      exemptBelowCharge?: number | null;
      exemptBelowLandedCost?: number | null;
      altCurrency?: string | null;
      altValuationBase?: string | null;
      notExemptedCodes?: Array<string | null> | null;
      shipFromCountries?: {
        __typename: "CountriesAndGroups";
        countryCodes?: Array<string | null> | null;
        groupCodes?: Array<string | null> | null;
      } | null;
      countriesOfOrigin?: {
        __typename: "CountriesAndGroups";
        countryCodes?: Array<string | null> | null;
        groupCodes?: Array<string | null> | null;
      } | null;
      specialType?: string | null;
    } | null> | null;
    countriesOfOrigin?: {
      __typename: "CountriesAndGroups";
      countryCodes?: Array<string | null> | null;
      groupCodes?: Array<string | null> | null;
    } | null;
    shipFromCountries?: {
      __typename: "CountriesAndGroups";
      countryCodes?: Array<string | null> | null;
      groupCodes?: Array<string | null> | null;
    } | null;
  } | null> | null;
  dataVersions?: Array<{
    __typename: "CountryDataVersion";
    isLatest?: boolean | null;
    externalVersion?: string | null;
    tariffVersion?: string | null;
    value?: string | null;
  } | null> | null;
  name?: string | null;
  isGroup?: boolean | null;
  tariff?: boolean | null;
  classification?: boolean | null;
  geoData?: boolean | null;
  zzRate?: string | null;
  defaultValuationBase?: string | null;
  declarableLength?: number | null;
};

export type ListJobsQuery = {
  __typename: "Job";
  country: string;
  jobStatus: JobStatus;
  startDate: string;
  eventId?: string | null;
  taskId?: string | null;
  endDate?: string | null;
  documentUrl?: string | null;
  errors?: string | null;
};

export type GetCountriesLoadInfoQuery = {
  __typename: "CountryLoadInfo";
  CountryCode: string;
  CountryName?: string | null;
  FilePath?: string | null;
  UnzippedFileName?: string | null;
  LoadedFileName?: string | null;
  SFTPReceiveDate?: string | null;
  DataLoadDate?: string | null;
  MoveSpreadsheetDate?: string | null;
  SpreadsheetID?: string | null;
  MoveFinished?: boolean | null;
};

export type GeoSearchQuery = {
  __typename: "GeoLocation";
  countryCode?: string | null;
  postalCode?: string | null;
  placeName?: string | null;
  placeNameASCII?: string | null;
  placeNameUk?: string | null;
  placeNameRu?: string | null;
  placeNameAKA?: string | null;
  adminName1?: string | null;
  adminName1Uk?: string | null;
  adminName1Ru?: string | null;
  adminCode1?: string | null;
  adminName2?: string | null;
  adminCode2?: string | null;
  adminName3?: string | null;
  adminCode3?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  accuracy?: string | null;
};

export type ClassifyQuery = {
  __typename: "Classification";
  countryCode: string;
  locale: string;
  fuzziness: boolean;
  items: Array<{
    __typename: "ClassifyItem";
    id?: string | null;
    hsCode?: string | null;
    rawHsCode?: string | null;
    productEn?: string | null;
    product?: string | null;
    categoryEn?: string | null;
    category?: string | null;
    subCategoryEn?: string | null;
    subCategory?: string | null;
    keywordsEn?: string | null;
    keywords?: string | null;
    descriptionEn?: string | null;
    description?: string | null;
    noExportFromUA?: boolean | null;
    noImportToUA?: boolean | null;
  }>;
};

export type GetNpRestrictionQuery = {
  __typename: "CommercialRestriction";
  productId: string;
  productName: Array<{
    __typename: "LanguageItem";
    locale: string;
    text: string;
  }>;
  hsCodes: Array<{
    __typename: "RestrictedHsCode";
    country: string;
    hsCode: string;
  }>;
  partners: Array<{
    __typename: "PartnerRestriction";
    partnerName: string;
    importRestrictions: {
      __typename: "RestrictionsByOwnership";
      individual: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
      business: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
    };
    exportRestrictions: {
      __typename: "RestrictionsByOwnership";
      individual: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
      business: Array<{
        __typename: "CommercialRestrictionItem";
        country: string;
        restriction: number;
      }>;
    };
  }>;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async RunJob(country: string): Promise<RunJobMutation> {
    const statement = `mutation RunJob($country: String!) {
        runJob(country: $country) {
          __typename
          country
          jobStatus
          startDate
          eventId
          taskId
          endDate
          documentUrl
          errors
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RunJobMutation>response.data.runJob;
  }
  async CreateTradeData(
    country: string,
    tradeData: TradeData
  ): Promise<CreateTradeDataMutation> {
    const statement = `mutation CreateTradeData($country: String!, $tradeData: TradeData!) {
        createTradeData(country: $country, tradeData: $tradeData) {
          __typename
          countryCode
          createdOn
          dataVersion
          declarable
          descEffective
          descExpiry
          description {
            __typename
            fullDescription
            itemLevelDescription
            language
          }
          hasChildItems
          hidden
          hsCode
          hsEffective
          hsExpiry
          indent
          level
          parent_version_hsCode_level_id
          unit
          updatedOn
          version_hsCode_level_id
          rates {
            __typename
            costCalculationItemName
            formula {
              __typename
              value
              minValue
              maxValue
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      tradeData
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTradeDataMutation>response.data.createTradeData;
  }
  async CreateNpRestriction(
    restriction: CommercialRestrictionInput
  ): Promise<CreateNpRestrictionMutation> {
    const statement = `mutation CreateNpRestriction($restriction: CommercialRestrictionInput!) {
        createNpRestriction(restriction: $restriction) {
          __typename
          productId
          productName {
            __typename
            locale
            text
          }
          hsCodes {
            __typename
            country
            hsCode
          }
          partners {
            __typename
            partnerName
            importRestrictions {
              __typename
              individual {
                __typename
                country
                restriction
              }
              business {
                __typename
                country
                restriction
              }
            }
            exportRestrictions {
              __typename
              individual {
                __typename
                country
                restriction
              }
              business {
                __typename
                country
                restriction
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      restriction
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNpRestrictionMutation>response.data.createNpRestriction;
  }
  async GetApiTokens(): Promise<GetApiTokensQuery> {
    const statement = `query GetApiTokens {
        getApiTokens {
          __typename
          xApiKey
          clientCredentials
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetApiTokensQuery>response.data.getApiTokens;
  }
  async GetNode(country: string, id: string): Promise<GetNodeQuery> {
    const statement = `query GetNode($country: String!, $id: ID!) {
        getNode(country: $country, id: $id) {
          __typename
          countryCode
          createdOn
          dataVersion
          declarable
          descEffective
          descExpiry
          description {
            __typename
            fullDescription
            itemLevelDescription
            language
          }
          hasChildItems
          hidden
          hsCode
          hsEffective
          hsExpiry
          indent
          level
          parent_version_hsCode_level_id
          unit
          updatedOn
          version_hsCode_level_id
          rates {
            __typename
            costCalculationItemName
            formula {
              __typename
              value
              minValue
              maxValue
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNodeQuery>response.data.getNode;
  }
  async ListNodesByLevel(
    country: string,
    level: number,
    hsCode: string,
    dataVersion?: string
  ): Promise<Array<ListNodesByLevelQuery>> {
    const statement = `query ListNodesByLevel($country: String!, $level: Int!, $hsCode: String!, $dataVersion: String) {
        listNodesByLevel(
          country: $country
          level: $level
          hsCode: $hsCode
          dataVersion: $dataVersion
        ) {
          __typename
          countryCode
          createdOn
          dataVersion
          declarable
          descEffective
          descExpiry
          description {
            __typename
            fullDescription
            itemLevelDescription
            language
          }
          hasChildItems
          hidden
          hsCode
          hsEffective
          hsExpiry
          indent
          level
          parent_version_hsCode_level_id
          unit
          updatedOn
          version_hsCode_level_id
          rates {
            __typename
            costCalculationItemName
            formula {
              __typename
              value
              minValue
              maxValue
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      level,
      hsCode
    };
    if (dataVersion) {
      gqlAPIServiceArguments.dataVersion = dataVersion;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListNodesByLevelQuery>>response.data.listNodesByLevel;
  }
  async ListNodes(
    country: string,
    hsPattern: string
  ): Promise<Array<ListNodesQuery>> {
    const statement = `query ListNodes($country: String!, $hsPattern: ID!) {
        listNodes(country: $country, hsPattern: $hsPattern) {
          __typename
          countryCode
          createdOn
          dataVersion
          declarable
          descEffective
          descExpiry
          description {
            __typename
            fullDescription
            itemLevelDescription
            language
          }
          hasChildItems
          hidden
          hsCode
          hsEffective
          hsExpiry
          indent
          level
          parent_version_hsCode_level_id
          unit
          updatedOn
          version_hsCode_level_id
          rates {
            __typename
            costCalculationItemName
            formula {
              __typename
              value
              minValue
              maxValue
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      hsPattern
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListNodesQuery>>response.data.listNodes;
  }
  async GetCountry(country: string): Promise<GetCountryQuery> {
    const statement = `query GetCountry($country: ID!) {
        getCountry(country: $country) {
          __typename
          countryCode
          countryGroups {
            __typename
            name
            countryCodes
            code
            abbreviation
          }
          currencyCode
          costCalculationItems {
            __typename
            name
            description
            type
            level
            domain
            formula
            valuationBase
            deMinimis {
              __typename
              shipmentMethod
              importer
              nonZeroRate
              exemptBelowValBase
              exemptBelowCharge
              exemptBelowLandedCost
              altCurrency
              altValuationBase
              notExemptedCodes
              shipFromCountries {
                __typename
                countryCodes
                groupCodes
              }
              countriesOfOrigin {
                __typename
                countryCodes
                groupCodes
              }
              specialType
            }
            countriesOfOrigin {
              __typename
              countryCodes
              groupCodes
            }
            shipFromCountries {
              __typename
              countryCodes
              groupCodes
            }
          }
          dataVersions {
            __typename
            isLatest
            externalVersion
            tariffVersion
            value
          }
          name
          isGroup
          tariff
          classification
          geoData
          zzRate
          defaultValuationBase
          declarableLength
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCountryQuery>response.data.getCountry;
  }
  async ListCountries(): Promise<Array<ListCountriesQuery>> {
    const statement = `query ListCountries {
        listCountries {
          __typename
          countryCode
          currencyCode
          name
          isGroup
          tariff
          classification
          geoData
          zzRate
          defaultValuationBase
          declarableLength
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListCountriesQuery>>response.data.listCountries;
  }
  async ListJobs(): Promise<Array<ListJobsQuery>> {
    const statement = `query ListJobs {
        listJobs {
          __typename
          country
          jobStatus
          startDate
          eventId
          taskId
          endDate
          documentUrl
          errors
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListJobsQuery>>response.data.listJobs;
  }
  async GetCountriesLoadInfo(): Promise<Array<GetCountriesLoadInfoQuery>> {
    const statement = `query GetCountriesLoadInfo {
        getCountriesLoadInfo {
          __typename
          CountryCode
          CountryName
          FilePath
          UnzippedFileName
          LoadedFileName
          SFTPReceiveDate
          DataLoadDate
          MoveSpreadsheetDate
          SpreadsheetID
          MoveFinished
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetCountriesLoadInfoQuery>>response.data.getCountriesLoadInfo;
  }
  async GeoSearch(
    country: string,
    keyword: string
  ): Promise<Array<GeoSearchQuery>> {
    const statement = `query GeoSearch($country: String!, $keyword: String!) {
        geoSearch(country: $country, keyword: $keyword) {
          __typename
          countryCode
          postalCode
          placeName
          placeNameASCII
          placeNameUk
          placeNameRu
          placeNameAKA
          adminName1
          adminName1Uk
          adminName1Ru
          adminCode1
          adminName2
          adminCode2
          adminName3
          adminCode3
          latitude
          longitude
          accuracy
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      keyword
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GeoSearchQuery>>response.data.geoSearch;
  }
  async Classify(
    country: string,
    classify: ClassifyInput
  ): Promise<ClassifyQuery> {
    const statement = `query Classify($country: String!, $classify: ClassifyInput!) {
        classify(country: $country, classify: $classify) {
          __typename
          countryCode
          locale
          fuzziness
          items {
            __typename
            id
            hsCode
            rawHsCode
            productEn
            product
            categoryEn
            category
            subCategoryEn
            subCategory
            keywordsEn
            keywords
            descriptionEn
            description
            noExportFromUA
            noImportToUA
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      country,
      classify
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ClassifyQuery>response.data.classify;
  }
  async GetNpRestriction(productId: string): Promise<GetNpRestrictionQuery> {
    const statement = `query GetNpRestriction($productId: String!) {
        getNpRestriction(productId: $productId) {
          __typename
          productId
          productName {
            __typename
            locale
            text
          }
          hsCodes {
            __typename
            country
            hsCode
          }
          partners {
            __typename
            partnerName
            importRestrictions {
              __typename
              individual {
                __typename
                country
                restriction
              }
              business {
                __typename
                country
                restriction
              }
            }
            exportRestrictions {
              __typename
              individual {
                __typename
                country
                restriction
              }
              business {
                __typename
                country
                restriction
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      productId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNpRestrictionQuery>response.data.getNpRestriction;
  }
}
