import {FlatTreeControl} from '@angular/cdk/tree';
import {Component} from '@angular/core';
import {ApiDataSource} from "./model/tree-data-source";
import {NodeService} from "../service/node.service";
import {UiCodeNode} from "./model/ui-code-node";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingModule} from "../app-routing.module";

/**
 * @title Tree with dynamic data
 */
@Component({
    selector: 'hs-tree',
    templateUrl: 'hs-tree.component.html',
    styleUrls: ['hs-tree.component.sass'],
})
export class HsTreeComponent {
    isLoading = false;

    dataBase: NodeService;

    constructor(private database: NodeService, private router: Router, private route: ActivatedRoute) {
        this.dataBase = database;

        this.treeControl = new FlatTreeControl<UiCodeNode>(
            (node: UiCodeNode) => node.getLevel(),
            (node: UiCodeNode) => node.isExpandable());
        this.dataSource = new ApiDataSource(this.treeControl, database);
    }

    treeControl: FlatTreeControl<UiCodeNode>;

    dataSource: ApiDataSource;

    hasChild = (_: number, _nodeData: UiCodeNode) => _nodeData.isExpandable();

    reloadTree(country: string) {
        console.log("reloadTree", country);
        this.isLoading = true;
        this.dataSource.data = [];
        this.dataBase.selectedCountry = country;
        this.dataBase.initialData().then(nodes => {
            this.dataSource.data = nodes;
            this.isLoading = false;
        });
    }

    toLandedCost(fromCountry: string, hsCode: string) {
        console.log("Calculate import from", fromCountry, " to ", this.dataBase.selectedCountry, " for ", hsCode);

        this.router.navigate(['landed-cost', {
            to: this.dataBase.selectedCountry,
            from: fromCountry,
            hsCode: hsCode
        }]);

    }
}
