<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="tree-progress-bar"></mat-progress-bar>

<form>
    <country-select (countrySelect)="reloadTree($event)"></country-select>
</form>

<mat-divider></mat-divider>

<mat-accordion>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ node.getHsCode() }}
                    </mat-panel-title>
                    <mat-panel-description *ngFor="let description of node.item.description">
                        {{ description.itemLevelDescription }}
                    </mat-panel-description>
                    <mat-panel-description>
                        <mat-chip [color]="'accent'"> {{ node.item.unit }}</mat-chip>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-grid-list cols="3" rowHeight="3:1">
                    <mat-grid-tile *ngFor="let rate of node.item.rates" [matTooltip]="rate?.formula?.value">
                        <mat-list-item>
                            <span matListItemTitle>{{ rate?.costCalculationItemName }}</span>
                            <span matListItemLine>{{ rate?.formula?.value }}</span>
                        </mat-list-item>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-label>Calculate import from</mat-label>
                <country-select (countrySelect)="toLandedCost($event, node.getHsCode())"></country-select>
            </mat-expansion-panel>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodeToggle matRipple>
            <button mat-icon-button [attr.aria-label]="'Expand ' + node.getHsCode()" matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            <mat-list-item>
                <span matListItemTitle>{{ node.getHsCode() }}</span>
                <span matListItemLine *ngFor="let description of node.item.description">
                    {{ description.itemLevelDescription }}
                </span>
            </mat-list-item>

            <mat-progress-bar *ngIf="node.isLoading()" mode="indeterminate"
                              class="tree-progress-bar"></mat-progress-bar>
        </mat-tree-node>
    </mat-tree>
</mat-accordion>
