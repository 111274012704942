import {Auth} from "aws-amplify";

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_H3PU3N3tR",
    "aws_user_pools_web_client_id": "6s0cbe2k1lfheo5ev42b46dqrt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_appsync_graphqlEndpoint": "https://gql.dev.customsrates.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    API: {
        endpoints: [
            {
                name: 'tda',
                endpoint: "https://api.dev.customsrates.com",
                authenticationType: 'AMAZON_COGNITO_USER_POOLS',
                custom_header: async () => {
                    return {
                        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
                        "x-api-key": "xzDopJzE8y6ntuBB2K5jw7kkTm5XSbUp4F4ddhYi"
                    }
                }
            }
        ]
    }
};


export default awsmobile;
