<mat-form-field class="country-full-width" appearance="fill">
    <mat-label>Country</mat-label>
    <input type="text"
           placeholder="Choose country"
           aria-label="Country"
           matInput
           [formControl]="autocompleteControl"
           [matAutocomplete]="auto">
    <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectCountry($event)">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{country.countryCode}} - {{country.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
