import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {EMPTY, Observable, startWith} from "rxjs";
import {map} from "rxjs/operators";
import {APIService, ListCountriesQuery} from "../../service/API.service";
import {CountryType} from "./country-type";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

/**
 * @title Tree with dynamic data
 */
@Component({
    selector: 'country-select',
    templateUrl: 'country-select.component.html',
    styleUrls: ['country-select.component.sass'],
})
export class CountrySelectComponent implements OnInit {
    countries: ListCountriesQuery[] = [];
    public selectedCountry: string = "";
    @Input() countryType: CountryType = CountryType.Tariff; //tariff | classify | geoData
    @Output() countrySelect = new EventEmitter<string>();

    public autocompleteControl = new FormControl(this.selectedCountry || '');

    public filteredCountries: Observable<ListCountriesQuery[]> = EMPTY;

    constructor(private api: APIService) {
    }

    async ngOnInit() {
        this.countries = await this.api.ListCountries();

        this.filteredCountries = this.autocompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterCountry(value ?? '')),
        );
    }

    private filterCountry(value: string): ListCountriesQuery[] {
        const filterValue = value.toLowerCase();
        return this.countries.filter(country =>
            (country.countryCode.toLowerCase().includes(filterValue) || (country.name ?? "")
                .toLowerCase()
                .includes(filterValue)) && this.filterByType(country));
    }

    private filterByType(country: ListCountriesQuery): boolean {
        return this.countryType == CountryType.Tariff && country.tariff === true ||
            this.countryType == CountryType.Classification && country.classification === true ||
            this.countryType == CountryType.GeoData && country.geoData === true;
    }

    selectCountry(event: MatAutocompleteSelectedEvent) {
        console.log("selectCountry", event);
        let country: ListCountriesQuery = event.option.value;
        if (this.selectedCountry != country.countryCode) {
            this.selectedCountry = country.countryCode;
            console.log("emit selectCountry event", this.selectedCountry)
            this.countrySelect.emit(country.countryCode);
        }
    }

    displayFn(country: ListCountriesQuery) {
        return country && country.countryCode ? country.countryCode + " - " + country.name : "";
    }
}
