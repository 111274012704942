import {Component, ViewChild} from '@angular/core';
import {APIService, ClassifyInput, ClassifyItem, ClassifyQuery} from "../service/API.service";
import {FormBuilder} from "@angular/forms";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    from,
    groupBy,
    mergeMap,
    Observable,
    switchMap,
    toArray
} from "rxjs";
import {CountrySelectComponent} from "../common/country-select/country-select.component";
import {map} from "rxjs/operators";

interface Category {
    category: string;
    items: ClassifyItem[];
}

@Component({
    selector: 'classify',
    templateUrl: './classify.component.html',
    styleUrls: ['./classify.component.sass']
})
export class ClassifyComponent {
    @ViewChild(CountrySelectComponent)
    private countrySelect!: CountrySelectComponent;
    isLoading: boolean = false;
    fuzzy: boolean = false;
    locales: Array<string> = ['En', 'Uk', 'De', 'Lt', 'Ro', 'Pl', 'Fr'];

    constructor(private api: APIService, private formBuilder: FormBuilder) {
    }

    classifyForm = this.formBuilder.group({
        locale: this.locales[0],
        fuzzy: false,
        product: '',
        category: '',
        subCategory: '',
        size: 20
    });
    categories: Observable<Category[]> = this.classifyForm.valueChanges.pipe(
        // startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => this.query({
            locale: val.locale ?? 'En',
            keyword: val.product ?? '',
            fuzzy: val.fuzzy ?? false,
            category: val.category ?? '',
            subCategory: val.subCategory ?? '',
            size: val.size ?? 20
        }))
    );
    displayResult: string = '';

    private query(classify: ClassifyInput): Observable<Category[]> {
        this.isLoading = true;
        return from(this.api.Classify(this.countrySelect.selectedCountry, classify)).pipe(
            catchError((error) => {
                console.error("Error occurred while fetching ClassifyData:", error);
                return [];  // Return an empty array in case of an error
            }),
            switchMap(this.categorizeClassification),
            finalize(() => this.isLoading = false)
        );
    }

    private categorizeClassification(classificationResponse: ClassifyQuery): Observable<Category[]> {
        return from(classificationResponse.items).pipe(
            groupBy(item => (item.categoryEn ?? "No category") + " | " + (item.category ?? "Без категорії")),
            mergeMap(group => group.pipe(
                toArray(),
                map(items => ({category: group.key, items}))
            )),
            toArray()
        );
    }

    selectItem(item: ClassifyItem) {
        this.displayResult = JSON.stringify(item, null, 2);
        console.log(item);
    }
}
