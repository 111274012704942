<div class="settings-container">
    <mat-form-field appearance="fill">
        <mat-label>client_id</mat-label>
        <input matInput disabled type="password">
        <button mat-icon-button matSuffix (click)="copyToken(0)" [attr.aria-label]="'Copy token'">
            <mat-icon>content_copy</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>client_secret</mat-label>
        <input matInput disabled type="password">
        <button mat-icon-button matSuffix (click)="copyToken(1)" [attr.aria-label]="'Copy token'">
            <mat-icon>content_copy</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>x-api-key</mat-label>
        <input matInput disabled type="password">
        <button mat-icon-button matSuffix (click)="copyToken(2)" [attr.aria-label]="'Copy token'">
            <mat-icon>content_copy</mat-icon>
        </button>
    </mat-form-field>
</div>
