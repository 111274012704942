import {Component} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Params} from "@angular/router";
import {catchError, finalize, from, Observable, of} from "rxjs";
import {RestAPI} from "@aws-amplify/api-rest";
import {map, switchMap} from "rxjs/operators";
import {LandedCostItem} from "./landed-cost-item";

interface LandedCostItemsRequest {
    toCountry: string;
    fromCountry: string;
    hsCode: string;
}

@Component({
    selector: 'landed-cost',
    templateUrl: './landed-cost.component.html',
    styleUrls: ['./landed-cost.component.sass']
})
export class LandedCostComponent {
    isLoading: boolean = false;

    landedCostItemsRequest: LandedCostItemsRequest = <LandedCostItemsRequest>{};
    landedCostItems: Array<Array<LandedCostItem>> = [];

    displayResult: string = '';
    result: any;

    constructor(private formBuilder: FormBuilder, route: ActivatedRoute) {
        route.params.pipe(
            map(LandedCostComponent.parseItemsRequest)
        ).subscribe(request => this.landedCostItemsRequest = request);

        of(this.landedCostItemsRequest).pipe(
            switchMap(request => this.fetchItems(request)),
            map(LandedCostComponent.parseLandedCostItems)
        ).subscribe(items => this.landedCostItems = items);
    }

    private fetchItems(itemsRequest: LandedCostItemsRequest): Observable<Array<Map<string, any>>> {
        this.isLoading = true;
        return from(RestAPI.get('tda', LandedCostComponent.getEndpoint(itemsRequest), {}))
            .pipe(
                catchError((error) => {
                    console.error("Error occurred while fetching LandedCostItems:", error);
                    this.isLoading = false;
                    return [];  // Return an empty array in case of an error
                }),
                finalize(() => this.isLoading = false)
            );
    }

    private static parseLandedCostItem(data: Map<string, any>): Array<LandedCostItem> {
        return Object.entries(data).map(([key, value]) => new LandedCostItem({
            key: key,
            label: key,
            type: value
        }));
    }

    private static parseLandedCostItems(data: Array<Map<string, any>>): Array<Array<LandedCostItem>> {
        return data.map(LandedCostComponent.parseLandedCostItem);
    }

    private static parseItemsRequest(params: Params) {
        return <LandedCostItemsRequest>{
            toCountry: params['to'],
            fromCountry: params['from'],
            hsCode: params['hsCode']
        };
    }

    private static convertLandedCostItems(data: Array<Array<LandedCostItem>>) {
        return data.map(LandedCostComponent.convertLandedCostItem);
    }

    private static convertLandedCostItem(data: Array<LandedCostItem>) {
        if (data.length == 1) return Object.fromEntries([[data[0].key, data[0].value]]);
        else {
            return Object.fromEntries(data.map(item => [item.key, item.value]));
        }
    }

    calculate(landedCostItems: Array<Array<LandedCostItem>>) {
        const items = LandedCostComponent.convertLandedCostItems(landedCostItems);

        this.calculateBackend(this.landedCostItemsRequest, items).subscribe(result => {
            this.displayResult = JSON.stringify(result, null, 2);
            this.result = result;
            console.log(result);
        });
    }

    private calculateBackend(itemsRequest: LandedCostItemsRequest, calculateRequest: any): Observable<any> {
        this.isLoading = true;
        const endpoint = LandedCostComponent.getEndpoint(itemsRequest);
        return from(RestAPI.post('tda', endpoint, {body: calculateRequest}))
            .pipe(
                catchError((error) => {
                    console.error("Error occurred while calculating LandedCost:", error);
                    this.isLoading = false;
                    this.displayResult = error.message ?? "Error occurred while calculating LandedCost";
                    return [];  // Return an empty array in case of an error
                }),
                finalize(() => this.isLoading = false)
            );
    }

    private static getEndpoint(itemsRequest: LandedCostItemsRequest) {
        return `/countries/${itemsRequest.toCountry}/landedCost/${itemsRequest.hsCode}/${itemsRequest.fromCountry}`;
    }
}
