import {Component, ViewChild} from '@angular/core';
import {APIService, GeoLocation} from "../service/API.service";
import {FormControl} from "@angular/forms";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    from,
    Observable,
    of,
    startWith,
    switchMap
} from "rxjs";
import {CountrySelectComponent} from "../common/country-select/country-select.component";

@Component({
    selector: 'geo-search',
    templateUrl: './geo.component.html',
    styleUrls: ['./geo.component.sass']
})
export class GeoComponent {
    @ViewChild(CountrySelectComponent)
    private countrySelect!: CountrySelectComponent;
    isLoading: boolean = false;

    myControl = new FormControl<string>('');
    items: Observable<GeoLocation[]> = this.myControl.valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => this.filter(val ?? ''))
    );

    selectedItem: GeoLocation | undefined;

    constructor(private api: APIService) {
    }

    private filter(query: string): Observable<GeoLocation[]> {
        this.isLoading = true;
        return from(this.api.GeoSearch(this.countrySelect.selectedCountry, query)).pipe(
            catchError((error) => {
                console.error("Error occurred while fetching GeoData:", error);
                return of([]);  // Return an empty array in case of an error
            }),
            finalize(() => this.isLoading = false));
    }

    selectItem(item: GeoLocation) {
        this.selectedItem = item;
    }
}
