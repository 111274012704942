import { Injectable } from '@angular/core';
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { APIService } from "./API.service";
import {Auth} from "aws-amplify";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private authenticator: AuthenticatorService, private api: APIService) {
    }

    // public getUserToken(): Promise<string> {
    //     return Auth.currentSession().then(session => session.getIdToken().getJwtToken());
    //     // return this.authenticator.user.getSignInUserSession()?.getIdToken().getJwtToken();
    // }

    // public getRefreshToken(): Promise<string> {
    //     return Auth.currentSession().then(session => session.getRefreshToken().getToken());
    // }

    public getUsagePlanKey(): Promise<string | undefined> {
        return this.api.GetApiTokens().then(tokens => tokens?.xApiKey);
    }

    public changePassword(oldPassword: string, newPassword: string) {
        this.authenticator.user.changePassword(oldPassword, newPassword, (err, data) => {
            if (err) {
                console.error(err);
            } else {
                console.log(data);
            }
        });
    }
}
