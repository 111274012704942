import { Injectable } from "@angular/core";
import { APIService, CodeNode, ListCountriesQuery } from "./API.service";
import { UiCodeNode } from "../hs-tree/model/ui-code-node";

@Injectable({ providedIn: 'root' })
export class NodeService {
    selectedCountry: string = "UA";

    constructor(private api: APIService) {
    }

    /** Initial data from database */
    initialData(): Promise<UiCodeNode[]> {
        return this.api.ListNodesByLevel(this.selectedCountry, 0, "")
            .then(chapters => chapters.map(chapter => new UiCodeNode(chapter)));
    }

    getCountries(): Promise<ListCountriesQuery[]> {
        return this.api.ListCountries();
    }

    getChildren(parentUiNode: UiCodeNode): Promise<UiCodeNode[]> {
        return (parentUiNode.getLevel() === 0 ? this.getHeadings(parentUiNode) : this.getDeeper(parentUiNode))
            .then(nodes => nodes
                .filter(node => node.level! > parentUiNode.getLevel())
                .sort((a, b) => a.hsCode!.localeCompare(b.hsCode!))
                .map(node => new UiCodeNode(node)));
    }

    getHeadings(parentUiNode: UiCodeNode): Promise<CodeNode[]> {
        return this.api.ListNodesByLevel(this.selectedCountry, 1, parentUiNode.getHsCode());
    }

    getDeeper(parentUiNode: UiCodeNode): Promise<CodeNode[]> {
        return this.api.ListNodes(this.selectedCountry, parentUiNode.getHsCode())
            .then(nodes => nodes.filter(node => node.level! > parentUiNode.getLevel()));
    }
}
