import { CollectionViewer, DataSource, SelectionChange } from "@angular/cdk/collections";
import { BehaviorSubject, merge, Observable } from "rxjs";
import { FlatTreeControl } from "@angular/cdk/tree";
import { map } from "rxjs/operators";
import { UiCodeNode } from "./ui-code-node";
import { NodeService } from "../../service/node.service";

export class ApiDataSource implements DataSource<UiCodeNode> {
  dataChange = new BehaviorSubject<UiCodeNode[]>([]);

  get data(): UiCodeNode[] {
    return this.dataChange.value;
  }

  set data(value: UiCodeNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<UiCodeNode>,
    private nodeService: NodeService,
  ) {
  }

  connect(collectionViewer: CollectionViewer): Observable<UiCodeNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<UiCodeNode>).added ||
        (change as SelectionChange<UiCodeNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<UiCodeNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<UiCodeNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: UiCodeNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (expand) {
      node.startLoad();
      this.nodeService.getChildren(node)?.then(children => {
        if (!children || index < 0) {
          // If no children, or cannot find the node, no op
          return;
        }
        this.data.splice(index + 1, 0, ...children);

        // notify the change
        this.dataChange.next(this.data);
        node.stopLoad();
      });
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].getLevel() > node.getLevel();
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
    }
  }
}
