import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from "@angular/material/snack-bar";
import { APIService } from "../../service/API.service";

export enum TokenType {
    CLIENT_ID, CLIENT_SECRET, API_KEY
}

@Component({
    selector: 'auth-settings',
    templateUrl: './settings.component.html',
    styleUrls: [ './settings.component.sass' ]
})
export class SettingsComponent {
    private apiTokens: Map<TokenType, string> = new Map();

    constructor(private clipboard: Clipboard,
                private snackBar: MatSnackBar,
                private api: APIService) {
        api.GetApiTokens().then(tokens => {
            const clientCredentials = atob(tokens?.clientCredentials || "").split(":");
            this.apiTokens.set(TokenType.CLIENT_ID, clientCredentials[0]);
            this.apiTokens.set(TokenType.CLIENT_SECRET, clientCredentials[1]);
            this.apiTokens.set(TokenType.API_KEY, tokens?.xApiKey);
        });
    }

    private showSnack(message: string) {
        this.snackBar.open(message, undefined, {
            duration: 3000
        })
    }

    public copyToken(tokenType: TokenType) {
        const token = this.apiTokens.get(tokenType);
        if (token) {
            this.clipboard.copy(token);
            this.showSnack('Copied the token!');
        } else {
            this.showSnack(`Failed to copy ${ tokenType } token!`);
        }
    }
}
