<mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="tree-progress-bar"></mat-progress-bar>
<div class="geo-container">
    <form class="geo-form">
        <country-select [countryType]="2"></country-select>
        <mat-form-field class="geo-full-width" appearance="fill">
            <mat-label>Type location name</mat-label>
            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of items | async" [value]="item.placeName" (onSelectionChange)="selectItem(item)">
                    {{item.placeName}} | {{item.postalCode}} | {{item.adminName1}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>
    <mat-divider></mat-divider>
    <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile>
            <code-highlight>{{ selectedItem | json }}</code-highlight>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
            <mat-card *ngIf="selectedItem as item">
                <mat-card-header>
                    <mat-card-title>Map</mat-card-title>
                    <mat-card-subtitle>Latitude: {{item.latitude}} | Longitude: {{item.longitude}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <geomap [location]="selectedItem"></geomap>
                </mat-card-content>
                <mat-card-actions>
                    <a mat-button href="https://www.google.com/maps/search/?api=1&query={{item.latitude}},{{item.longitude}}"
                       target="_blank">Open in Google Maps</a>
                </mat-card-actions>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
