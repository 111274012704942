export class LandedCostItem {
    value: string | number;
    key: string;
    label: string;
    // required: boolean;
    // order: number;
    // controlType: string;
    type: string;

    // options: {key: string, value: string}[];

    constructor(options: {
        key?: string;
        label?: string;
        // required?: boolean;
        // order?: number;
        // controlType?: string;
        type?: string;
        // options?: {key: string, value: string}[];
    } = {}) {
        this.value = options.type == "Double" ? 0 : '';
        this.key = options.key ?? '';
        this.label = options.label ?? '';
        // this.required = !!options.required;
        // this.order = options.order === undefined ? 1 : options.order;
        // this.controlType = options.controlType || '';
        this.type = options.type == "Double" ? 'number' : 'text';
        // this.options = options.options || [];
    }
}
