import {Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import SwaggerUI from 'swagger-ui';
import {AuthService} from "../service/auth.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-api-docs',
    templateUrl: './api-docs.component.html',
    styleUrls: ['./api-docs.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ApiDocsComponent {
    @ViewChild('apidocs', {static: true}) apiDocsElement: ElementRef | undefined
    @ViewChild('progress', {static: true}) progressElement: ElementRef | undefined
    loading: boolean = true
    showSettings: boolean = false;

    constructor(private authService: AuthService, private route: ActivatedRoute) {
    }

    ngAfterContentInit(): void {
        this.route.data.subscribe(data => {
            if (data["type"] == "API") {
                const ui = SwaggerUI({
                    url: `${environment.metaFileUrl}/rest.yaml`,
                    domNode: this.apiDocsElement?.nativeElement,
                    persistAuthorization: true,
                    supportedSubmitMethods: ['get', 'post', 'put'],
                    onComplete: () => {
                        this.authService.getUsagePlanKey().then(token => {
                            ui.preauthorizeApiKey("x_api_key", token)
                            this.loading = false
                        });
                    }
                })
            } else if (data["type"] == "Auth") {
                const ui = SwaggerUI({
                    url: `${environment.metaFileUrl}/auth.yaml`,
                    domNode: this.apiDocsElement?.nativeElement,
                    persistAuthorization: false,
                    supportedSubmitMethods: ['post'],
                    onComplete: () => {
                        this.showSettings = true;
                        this.loading = false
                    }
                })
            }
        });
    }
}
