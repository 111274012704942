import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HsTreeComponent } from './hs-tree/hs-tree.component';
import { MaterialModule } from "./material.module";
import { ApiDocsComponent } from './api-docs/api-docs.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GeoComponent } from "./geo/geo.component";
import { CountrySelectComponent } from "./common/country-select/country-select.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MapComponent } from "./common/map/map.component";
import { ClassifyComponent } from "./classification/classify.component";
import { CodeComponent } from "./common/highlight/code.component";
import {SettingsComponent} from "./api-docs/settings/settings.component";
import {LandedCostComponent} from "./landed-cost/landed-cost.component";

@NgModule({
    declarations: [
        AppComponent,
        CountrySelectComponent,
        MapComponent,
        CodeComponent,
        HsTreeComponent,
        ApiDocsComponent,
        SettingsComponent,
        GeoComponent,
        ClassifyComponent,
        LandedCostComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AmplifyAuthenticatorModule,
        LayoutModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        LeafletModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
