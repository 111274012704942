import { CodeNode, Rate } from "../../service/API.service";

export class UiCodeNode {
    constructor(
        public item: CodeNode,
        private loading = false
    ) {
    }

    getLevel(): number {
        return this.item.level ? this.item.level : 0;
    }

    isExpandable(): boolean {
        return this.item && !!this.item?.hasChildItems;
    }

    getHsCode(): string {
        return this.item.hsCode ? this.item.hsCode : "";
    }

    public getDescription(): string {
        return this.item.description ? this.item.description[0]!.itemLevelDescription : "¯\\_(ツ)_/¯";
    }

    startLoad(): void {
        this.loading = true;
    }

    stopLoad(): void {
        this.loading = false;
    }

    public isLoading(): boolean {
        return this.loading;
    }
}
