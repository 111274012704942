<amplify-authenticator [hideSignUp]="true" [loginMechanisms]="['email']">
    <ng-template
            amplifySlot="authenticated"
            let-user="user"
            let-signOut="signOut">
        <mat-toolbar #toolbar color="primary">
            <img src="favicon.png" alt="logo" class="logo">
            <span class="bar-spacer"></span>
            <span>CustomsRates User Area</span>
            <span class="bar-spacer"></span>
            <button (click)="signOut()" mat-icon-button aria-label="Sign-out">
                <mat-icon>logout</mat-icon>
            </button>

        </mat-toolbar>

        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" fixedInViewport [fixedTopGap]="toolbar._elementRef.nativeElement.offsetHeight"
                         [mode]="'side'" [opened]="(isHandset$ | async) === false">
                <mat-nav-list>
                    <a mat-list-item routerLink="/hs-tree" routerLinkActive="active" ariaCurrentWhenActive="page">Hs Tree</a>
                    <a mat-list-item routerLink="/classify" routerLinkActive="active" ariaCurrentWhenActive="page">Classification</a>
                    <a mat-list-item routerLink="/geo" routerLinkActive="active" ariaCurrentWhenActive="page">Geo Data</a>
                    <a mat-list-item routerLink="/api-docs" routerLinkActive="active" ariaCurrentWhenActive="page">API Docs</a>
                    <a mat-list-item routerLink="/auth-docs" routerLinkActive="active" ariaCurrentWhenActive="page">API Auth Docs</a>
                </mat-nav-list>

            </mat-sidenav>
            <mat-sidenav-content>

                <router-outlet></router-outlet>

            </mat-sidenav-content>
        </mat-sidenav-container>
    </ng-template>
</amplify-authenticator>
